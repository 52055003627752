import React from "react"
import Layout from "../components/Layout/Layout"
import { makeStyles } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Grid from "@material-ui/core/Grid";
import HelloWorld from "../components/Index/HelloWorld/HelloWorld";
import About from "../components/Index/About/About";
import DevTwitter from "../components/Index/DevTwitter/DevTwitter";
import { width } from "@material-ui/system"

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: "8px",
        width: "100%"
    },
    // main_content: {
    //     height: "100%",
    //     width: "100%",
    // },
    // main_content_grid: {
    //     height: "100%",
    //     width: "100%",
    // },
    // dev_twitter: {
    //     height: "100%",
    //     padding: "16px",
    //     [theme.breakpoints.down("sm")]: {
    //         display: "none",
    //     },
    // },

}));

export default function () {

    const classes = useStyles();

    return (

        <Layout>
            {/* MAIN CONTENT */}
            <Grid container className={classes.root}>

                <Grid item xs={12} sm={8}>

                    <Grid container direction="column" alignItems="center">

                        <Grid item xs={12} style={{width: "80%"}}>
                            <HelloWorld />
                        </Grid>

                    </Grid>

                </Grid>
                
            </Grid>

        </Layout>
    )
}
