import React from "react"
import {Card, makeStyles} from "@material-ui/core"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import CardActionArea from "@material-ui/core/CardActionArea";
import {Link} from "gatsby"

const useStyles = makeStyles({
    card: {
        display: "flex",
        width: "100%",
    },
    root: {
        display: "flex",
        flexDirection: "column",
    },
    content: {
        flex: "1 0 auto",
    },
    media: {
        width: "140px",
        objectFit: "contain",
    },
});

export default function About(props) {
    const classes = useStyles();
    return (
        <Link to={props.link} style={{textDecoration: "none"}}>
            <Card className={classes.card} style={{height: props.height}}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={props.mediaImage}
                        component="img"
                    />
                    <CardContent className={classes.content}>
                        <Typography component="h5" variant="h5" style={{textDecoration: "none"}}>
                            {props.title}
                        </Typography>
                        {props.children}
                    </CardContent>
                </CardActionArea>
            </Card>
        </Link>
    )
}