import C_LOGO from "../../../data/hello_world/c.svg"
import DOCKER_LOGO from "../../../data/hello_world/docker.svg"
import LINUX_LOGO from "../../../data/hello_world/linux.svg"
import GIT_LOGO from "../../../data/hello_world/git-icon.svg"
import KOTLIN_LOGO from "../../../data/hello_world/kotlin.svg"
import SPRING_LOGO from "../../../data/hello_world/spring.svg"
import PYTHON_LOGO from "../../../data/hello_world/python.svg"
import DJANGO_LOGO from "../../../data/hello_world/django.svg"
import {random} from "lodash";

const Code_Hello = [
    {
        icon: LINUX_LOGO,
        code: "echo \"Hello World\""
    },
    {
        icon: C_LOGO,
        code: "printf(\"Hello World\");"
    }, {
        icon: DOCKER_LOGO,
        code: "docker run hello-world"
    },
    {
        icon: GIT_LOGO,
        code: "git commit -m \"Hello World\""
    },
    {
        icon: KOTLIN_LOGO,
        code: "println(\"Hello World!\")"
    },
    {
        icon: SPRING_LOGO,
        code: "SpringApplication.run(HelloWorld.class, args);"
    },
    {
        icon: PYTHON_LOGO,
        code: "print(\"Hello World\")"
    },
    {
        icon: DJANGO_LOGO,
        code: "return HttpResponse(\"Hello World\")",
    }
];

export default function () {
    return Code_Hello[random(0, Code_Hello.length - 1)];
}