import React, {Component} from "react";
import {Typography} from "@material-ui/core";
import CodeHello from "./CodeHello";
import Typewriter from "typewriter-effect";
import {random} from "lodash";

class HelloWorld extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: {
                icon: <></>,
                code: ""
            }
        };

        this.typeCode = this.typeCode.bind(this);
    }


    componentWillUnmount() {
        this.typewriter.stop();
    }


    componentDidMount() {
        this.setState({current: CodeHello()})
    }

    async typeCode(typewriter) {

        this.typewriter = typewriter;
        this.typewriter.start();

        console.log(this.state.current.code);
        while (true) {
            await this.typeCodePromise(this.typewriter);
            this.setState({current: CodeHello()})
        }
    }

    typeCodePromise(type_writer) {
        return new Promise(resolve => {
            type_writer
                .typeString(this.state.current.code)
                .pauseFor(1500)
                .deleteAll()
                .callFunction(() => resolve(type_writer), type_writer)
        });
    }

    render() {
        return (
            <div style={{marginTop: "32px", maxWidth: "760px"}}>
                <Typography variant="h4">
                    Building Cool Stuff in a
                </Typography>
                <hr/>
                <div style={{marginRight: "16px", marginTop: "8px", float: "left"}}>
                    <img src={this.state.current.icon} alt=""
                         style={{width: "50px", height: "50px"}}/>
                </div>
                <Typography variant="h5" style={{marginTop: "24px"}}>
                    <Typewriter
                        onInit={(typewriter) => this.typeCode(typewriter)}/>
                </Typography>
            </div>
        )
    }
}

export default HelloWorld